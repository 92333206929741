export interface IReport {
  id?: string
  department_name?: string
  job_category?: string
  job_number?: string
  job_code1?: string
  job_code2?: string
  job_date?: string
  weather_id?: string
  temperature?: string
  construction?: string
  contract_id?: string
  contract_amount?: string
  construction_period_start?: string
  construction_period_end?: string
  progress_rate?: number
  department_id?: string
  all_department_id?: string[]
  office_name?: string
  construction_id?: string
  construction_detail_id?: string
  inspector?: string
  department_manager?: string
  section_manager?: string
  project_manager?: string
  operators?: IOperator[]
  construction_company?: string
  note?: string
  partners?: string[]
  comment?: string | IReportComments[]
  request_comment?: string | IReportComments[]
  inspections?: string[]
  questions?: IReportQuestion[]
  status?: number
  create_date?: string
  update_date?: string
  user_id?: string
  user_name?: string
  errors?: IReportError
  before_questions?: IReportQuestion[]
}

export function isReport(obj: unknown): obj is IReport {
  if (typeof obj !== "object" || obj === null) {
    console.error("Object is not an object or is null")
    return false
  }

  const report = obj as Record<string, unknown>

  const stringProps = [
    "id",
    "department_name",
    "job_category",
    "job_number",
    "job_code1",
    "job_code2",
    "job_date",
    "weather_id",
    "temperature",
    "construction",
    "contract_id",
    "contract_amount",
    "construction_period_start",
    "construction_period_end",
    "department_id",
    "office_name",
    "construction_id",
    "construction_detail_id",
    "inspector",
    "department_manager",
    "section_manager",
    "project_manager",
    "construction_company",
    "create_date",
    "update_date",
    "user_id",
    "user_name",
  ]
  for (const prop of stringProps) {
    if (prop in report && typeof report[prop] !== "string") {
      console.error(`Property '${prop}' is not a string`)
      return false
    }
  }

  const numberProps = ["progress_rate"]
  for (const prop of numberProps) {
    if (prop in report && typeof report[prop] !== "number") {
      console.error(`Property '${prop}' is not a number`)
      return false
    }
  }

  const arrayProps = ["all_department_id", "partners", "inspections"]
  for (const prop of arrayProps) {
    if (prop in report && !Array.isArray(report[prop])) {
      console.error(`Property '${prop}' is not an array`)
      return false
    }
  }

  if ("operators" in report && !isArrayOperators(report["operators"])) {
    console.error("Property 'operators' is not an array of IOperator")
    return false
  }

  if ("comment" in report && !isArrayReportComments(report["comment"])) {
    console.error("Property 'comment' is not an array of IReportComments")
    return false
  }

  if ("request_comment" in report && !isArrayReportComments(report["request_comment"])) {
    console.error("Property 'request_comment' is not an array of IReportComments")
    return false
  }

  if ("questions" in report && !isArrayReportQuestions(report["questions"])) {
    console.error("Property 'questions' is not an array of IReportQuestion")
    return false
  }

  return true
}
export interface DraftReport extends IReport {
  draftId?: string
}

export interface DraftRecord {
  id?: string
  user_id?: string
  report?: IReport
  create_date?: string
}

export function isDraftRecord(obj: unknown): obj is DraftRecord {
  if (typeof obj !== "object" || obj === null) {
    console.error("Object is not an object or is null")
    return false
  }

  const record = obj as Record<string, unknown>

  const stringProps = ["id", "user_id", "create_date"]
  for (const prop of stringProps) {
    if (prop in record && typeof record[prop] !== "string") {
      console.error(`Property '${prop}' is not a string`)
      return false
    }
  }

  if ("report" in record && !isReport(record["report"])) {
    console.error("Property 'report' is not an object")
    return false
  }

  return true
}

export function isArrayDraftRecords(obj: unknown): obj is DraftRecord[] {
  if (!Array.isArray(obj)) {
    console.error("Object is not an array in isArrayDraftRecords")
    return false
  }

  return obj.every((item) => isDraftRecord(item))
}

export interface IOperator {
  operator_id?: string
  operator_number?: number | null
  operator_other?: string | IOperatorOther[] | null
}

function isOperator(obj: unknown): obj is IOperator {
  if (typeof obj !== "object" || obj === null) {
    console.error("Object is not an object or is null")
    return false
  }

  const operator = obj as Record<string, unknown>

  const stringProps = ["operator_id"]
  for (const prop of stringProps) {
    if (prop in operator && typeof operator[prop] !== "string") {
      console.error(`Property '${prop}' is not a string`)
      return false
    }
  }

  const numberProps = ["operator_number"]
  for (const prop of numberProps) {
    if (prop in operator && operator[prop] !== null && typeof operator[prop] !== "number") {
      console.error(`Property '${prop}' is not a number or null`)
      return false
    }
  }

  const otherProps = ["operator_other"]
  for (const prop of otherProps) {
    if (prop in operator && !isArrayORStringReportOperatorOther(operator[prop])) {
      console.error(`Property '${prop}' is not an array of IOperatorOther`)
      return false
    }
  }

  return true
}

export function isArrayOperators(obj: unknown): obj is IOperator[] {
  if (!Array.isArray(obj)) {
    console.error("Object is not an array in isArrayOperators")
    return false
  }
  return obj.every((item) => isOperator(item))
}

export interface IOperatorOther {
  other_name: string
  other_number: number | null
}

export function isOperatorOther(obj: unknown): obj is IOperatorOther {
  if (typeof obj !== "object" || obj === null) {
    console.error("Object is not an object or is null")
    return false
  }

  const operator = obj as Record<string, unknown>

  const stringProps = ["other_name"]
  for (const prop of stringProps) {
    if (prop in operator && typeof operator[prop] !== "string") {
      console.error(`Property '${prop}' is not a string`)
      return false
    }
  }

  const numberProps = ["other_number"]
  for (const prop of numberProps) {
    if (prop in operator && operator[prop] !== null && typeof operator[prop] !== "number") {
      console.error(`Property '${prop}' is not a number or null`)
      return false
    }
  }

  return true
}

export function isArrayORStringReportOperatorOther(obj: unknown): obj is IOperatorOther[] {
  if (typeof obj === "string") {
    return true
  }
  if (!Array.isArray(obj)) {
    console.error("Object is not an array in isArrayORStringReportOperatorOther")
    return false
  }

  return obj.every((item) => isOperatorOther(item))
}

export interface IReportQuestion {
  inspection_id?: string
  inspection_name?: string | null
  department_id?: string
  department_name?: string
  id?: string
  result_id?: string
  view_number?: number
  class_name?: string
  name?: string
  comment?: string
  image?: string
  //image_fileはアップロードの際に一時的に保持するデータ DBにはない
  image_file?: File | null
  status?: number
  create_date?: string
  update_date?: string
}

export function isReportQuestion(obj: unknown): obj is IReportQuestion {
  if (typeof obj !== "object" || obj === null) {
    console.error("Object is not an object or is null")
    return false
  }

  const question = obj as Record<string, unknown>

  const stringOrNullProps = [
    "inspection_name",
    "comment",
    "image",
    "department_name",
    "inspection_id",
    "department_id",
    "class_name",
  ]
  for (const prop of stringOrNullProps) {
    if (prop in question && question[prop] !== null && typeof question[prop] !== "string") {
      console.error(`Property '${prop}' is not a string or null`)
      return false
    }
  }

  const stringProps = ["id", "result_id", "name", "create_date", "update_date"]
  for (const prop of stringProps) {
    if (prop in question && typeof question[prop] !== "string") {
      console.error(`Property '${prop}' is not a string`)
      return false
    }
  }

  const numberProps = ["view_number", "status"]
  for (const prop of numberProps) {
    if (prop in question && typeof question[prop] !== "number") {
      console.error(`Property '${prop}' is not a number`)
      return false
    }
  }

  return true
}

export function isArrayReportQuestions(obj: unknown): obj is IReportQuestion[] {
  if (!Array.isArray(obj)) {
    console.error("Object is not an array in isArrayReportQuestions")
    return false
  }

  return obj.every((item) => isReportQuestion(item))
}

export interface IReportError {
  id?: string
  job_category?: string
  job_number?: string
  job_date?: string
  job_code1?: string
  job_code2?: string
  weather_id?: string
  temperature?: string
  construction?: string
  contract_id?: string
  contract_amount?: string
  construction_period_start?: string
  construction_period_end?: string
  progress_rate?: string
  department_id?: string
  office_name?: string
  construction_id?: string
  construction_detail_id?: string
  inspector?: string
  department_manager?: string
  section_manager?: string
  project_manager?: string
  operators?: IOperatorError[]
  construction_company?: string
  partners?: string[]
  comment?: ICommentError[]
  note?: string
  request_comment?: ICommentError[]
  inspections?: string
  questions?: IQuestionError[]
}
export interface ICommentError {
  author?: string
  comment?: string
}
export interface IOperatorError {
  idMessage?: string
  numMessage?: string
  otherMessage?: string
}

export interface IQuestionError {
  question_id?: string
  comment?: string
  image?: string
}
//選択肢用に使用する型
export interface IOption {
  id?: string
  viewNumber?: number
  class_name?: string
  name?: string
  // disabledはoperatorのみに使用
  disabled?: boolean
  // job_numberはdepartmentのみに使用
  job_number?: string
}

export interface IReportSearch {
  job_category?: string
  job_number?: string
  job_code1?: string
  job_code2?: string
  job_date_start?: string
  job_date_end?: string
  weather_id?: string[]
  construction?: string
  contract_id?: string[]
  contract_amount_start?: string
  contract_amount_end?: string
  department_id?: string[]
  office_name?: string
  construction_id?: string[]
  construction_detail_id?: string[]
  inspector?: string
  department_manager?: string
  section_manager?: string
  project_manager?: string
  operators?: string[]
  construction_company?: string
  partners?: string
  comment?: string
  request_comment?: string
  inspections?: string[]
}

export interface IBeforeReportSearch {
  job_category?: string
  job_number?: string
  job_date_start?: string
  job_date_end?: string
  weather_id?: string[]
  construction?: string
  contract_id?: string[]
  contract_amount_start?: string
  contract_amount_end?: string
  department_id?: string[]
  office_name?: string
  construction_id?: string[]
  construction_detail_id?: string[]
  inspector?: string
  department_manager?: string
  section_manager?: string
  project_manager?: string
  operators?: IOperator[]
  construction_company?: string
  partners?: string
  comment?: string | IReportComments[]
  request_comment?: string | IReportComments[]
  inspections?: string[]
}

export interface ISortType {
  type?: string
  order?: string
}

export interface IReportComments {
  author: string
  comment: string
}

export function isValidReportComments(object: unknown): object is IReportComments {
  if (typeof object !== "object" || object === null || Array.isArray(object)) {
    return false
  }

  const report = object as Record<string, unknown>

  return (
    "author" in report &&
    typeof report["author"] === "string" &&
    "comment" in report &&
    typeof report["comment"] === "string"
  )
}

export function isArrayReportComments(object: unknown): object is IReportComments[] {
  if (!Array.isArray(object)) {
    return false
  }

  return object.every((item) => isValidReportComments(item))
}

export type OldImages = {
  questionId: string
  imagePath: string
}
