import * as api from "@/lib/api"
import { AuthContext } from "@/lib/auth"
import { IOperator, IOperatorOther, IOption, IReport, IReportComments, IReportQuestion } from "@/lib/interface"
import { errorHandle, reportCsvDownload } from "@/lib/system"
import { parseComments, parseOperatorsOthers } from "@/lib/utils"
import React, { Fragment, useContext, useEffect, useReducer, useState } from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Image from "react-bootstrap/Image"
import Row from "react-bootstrap/Row"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { Link } from "react-router-dom"
import QuestionAnswer from "../common/question-answer"
import DeleteModal from "./_delete_modal"
import { ACTIONTYPE, initialReport, reducerFunc } from "./form-func"

function App() {
  const { t } = useTranslation()
  const params = useParams<any>()
  const [report, reportDispatch] = useReducer<React.Reducer<IReport, ACTIONTYPE>>(reducerFunc, initialReport)
  const [weathers, setWeathers] = useState<IOption[]>([])
  const [contracts, setContracts] = useState<IOption[]>([])
  const [constructions, setConstructions] = useState<IOption[]>([])
  const [constructionDetails, setConstructionDetails] = useState<IOption[]>([])
  const [operators, setOperators] = useState<IOption[]>([])
  const [inspections, setInspections] = useState<IOption[]>([])
  const [deleteModalShow, setDeleteModalShow] = useState(false)
  const [delReportId, setDelReportId] = useState<string>("")
  const auth = useContext(AuthContext)
  const inspectors = report.inspector ? report.inspector.split(",") : [""]
  const [comment, setComment] = useState<IReportComments[]>([])
  const [request_comment, setRequestComment] = useState<IReportComments[]>([])
  const [operatorsOthers, setOperatorsOthers] = useState<IOperatorOther[]>([])
  useEffect(() => {
    getOptions()
    if (params.id) {
      getReport(params.id)
    }
  }, [])

  useEffect(() => {
    if (report?.operators) {
      report.operators.forEach((operator: IOperator) => {
        if (operator.operator_id === "8855b643-775c-0c5b-0456-42c3d5b799e3" && operator.operator_other) {
          const parsedOperator = parseOperatorsOthers(operator.operator_other)
          setOperatorsOthers(parsedOperator)
        }
      })
    }
  }, [report.operators])

  useEffect(() => {
    if ("string" === typeof report.comment) {
      const parsedComment = parseComments(report?.comment)
      setComment(parsedComment)
    }
    if ("string" === typeof report.request_comment) {
      const request_comment = parseComments(report?.request_comment)
      setRequestComment(request_comment)
    }
  }, [report.comment, report.request_comment])

  const getReport = async (id: string) => {
    try {
      const reportJson: any = await api.getReportDb(id)
      if (reportJson?.data) {
        reportDispatch({
          type: "SET_REPORT",
          payload: reportJson.data.json,
        })
      }
    } catch (err) {
      console.log(errorHandle(err))
    }
  }

  const getOptions = async () => {
    try {
      const weatherJson: any = await api.getWeathersDb()
      if (weatherJson?.data) {
        setWeathers(weatherJson?.data.json)
      }
      const contractJson: any = await api.getContractsDb()
      if (contractJson?.data) {
        setContracts(contractJson?.data.json)
      }
      const constructionJson: any = await api.getConstructionsDb()
      if (constructionJson?.data) {
        setConstructions(constructionJson?.data.json)
      }
      const constructionDetailJson: any = await api.getConstructionDetailsDb()
      if (constructionDetailJson?.data) {
        setConstructionDetails(constructionDetailJson?.data.json)
      }
      const operatorJson: any = await api.getOperatorsDb()
      if (operatorJson?.data) {
        setOperators(operatorJson?.data.json)
      }
      const inspectionJson: any = await api.getInspectionsDb()
      if (inspectionJson?.data) {
        setInspections(inspectionJson?.data.json)
      }
    } catch (err) {
      console.log(errorHandle(err))
    }
  }

  const handlerCsvDownload = async () => {
    if (report) {
      await reportCsvDownload(
        t,
        report,
        weathers,
        contracts,
        constructions,
        constructionDetails,
        operators,
        inspections
      )
    }
  }

  const handleDelete = () => {
    setDeleteModalShow(true)
    setDelReportId(report.id || "")
  }

  return (
    <>
      <DeleteModal delReportId={delReportId} show={deleteModalShow} onHide={() => setDeleteModalShow(false)} />
      {report?.id && (
        <>
          <div className="d-none d-xl-block">
            <Col className="mb-4">
              <button onClick={() => handlerCsvDownload()} className="btn btn-info text-white">
                Excel用CSVダウンロード
              </button>
            </Col>
          </div>
          <div>
            <Col className="mb-4">
              <Link to={`/report/${report?.id}/pdf`} className="btn btn-primary text-white">
                PDF
              </Link>
            </Col>
          </div>
        </>
      )}
      <Row className="align-items-center py-3 border-top border-bottom">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          作成者ID
        </Col>
        <Col md={12} lg={10}>
          {report?.user_name}
        </Col>
      </Row>
      <Row className="align-items-center py-3  border-bottom">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          部門
        </Col>
        <Col md={12} lg={10}>
          {report?.department_name}
        </Col>
      </Row>
      <Row className="align-items-center py-3 border-bottom bg-white">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          JOB番号
        </Col>
        <Col md={12} lg={10}>
          {`${report?.job_category}-${report?.job_number}${report?.job_code1 ? "-" + report?.job_code1 : ""}${
            report?.job_code2 ? "-" + report?.job_code2 : ""
          }`}
        </Col>
      </Row>
      <Row className="align-items-center py-3 border-bottom">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          年月日
        </Col>
        <Col md={12} lg={10}>
          {report?.job_date}
        </Col>
      </Row>
      <Row className="align-items-center py-3 border-bottom bg-white">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          天候
        </Col>
        <Col md={12} lg={10}>
          {
            weathers?.find((weather: IOption) => {
              return weather.id == report?.weather_id
            })?.name
          }
        </Col>
      </Row>
      <Row className="align-items-center py-3 border-bottom">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          気温
        </Col>
        <Col md={12} lg={10}>
          {report?.temperature && `${report?.temperature} °C`}
        </Col>
      </Row>
      <Row className="align-items-center py-3 border-bottom bg-white">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          工事名
        </Col>
        <Col md={12} lg={10}>
          {report?.construction}
        </Col>
      </Row>
      <Row className="align-items-center py-3 border-bottom">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          請負区分
        </Col>
        <Col md={12} lg={10}>
          {
            contracts?.find((contract: IOption) => {
              return contract.id == report?.contract_id
            })?.name
          }
        </Col>
      </Row>
      <Row className="align-items-center py-3 border-bottom bg-white">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          請負金額
        </Col>
        <Col md={12} lg={10}>
          {report?.contract_amount && `${report?.contract_amount} 百万円`}
        </Col>
      </Row>
      <Row className="align-items-center py-3 border-bottom">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          工期開始日
        </Col>
        <Col md={12} lg={10}>
          {report?.construction_period_start}
        </Col>
      </Row>
      <Row className="align-items-center py-3 border-bottom bg-white">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          工期完了日
        </Col>
        <Col md={12} lg={10}>
          {report?.construction_period_end}
        </Col>
      </Row>
      <Row className="align-items-center py-3 border-bottom bg-white">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          進捗率
        </Col>
        <Col md={12} lg={10}>
          {report?.progress_rate && `${report?.progress_rate} %`}
        </Col>
      </Row>
      <Row className="align-items-center py-3 border-bottom bg-white">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          部署
        </Col>
        <Col md={12} lg={10}>
          {report?.office_name}
        </Col>
      </Row>
      <Row className="align-items-center py-3 border-bottom">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          工事種類
        </Col>
        <Col md={12} lg={10}>
          種類1：
          {
            constructions?.find((construction: IOption) => {
              return construction.id == report?.construction_id
            })?.name
          }
          <br />
          種類2：
          {
            constructionDetails?.find((constructionDetail: IOption) => {
              return constructionDetail.id == report?.construction_detail_id
            })?.name
          }
        </Col>
      </Row>
      <Row className="align-items-center py-3 border-bottom bg-white">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          点検者
        </Col>
        <Col md={12} lg={10}>
          {inspectors?.map((inspector: string, index: number) => {
            return <div key={`inspector-${index}`}>{inspector}</div>
          })}
        </Col>
      </Row>
      <Row className="align-items-center py-3 border-bottom">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          部長
        </Col>
        <Col md={12} lg={10}>
          {report?.department_manager}
        </Col>
      </Row>
      <Row className="align-items-center py-3 border-bottom bg-white">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          課長
        </Col>
        <Col md={12} lg={10}>
          {report?.section_manager}
        </Col>
      </Row>
      <Row className="align-items-center py-3 border-bottom">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          被点検者
        </Col>
        <Col md={12} lg={10}>
          {report?.project_manager}
        </Col>
      </Row>
      <Row className="align-items-center py-3 border-bottom bg-white">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          当日の作業員人数
        </Col>
        <Col md={12} lg={10}>
          {report?.operators?.map((operator: IOperator, index: number) => {
            if (operator.operator_id === "8855b643-775c-0c5b-0456-42c3d5b799e3") {
              const displayOthers = operatorsOthers
                .filter((other: IOperatorOther) => other.other_name)
                .map((other: IOperatorOther, index: number) => {
                  if (other.other_name) {
                    return (
                      <div key={`operator-${index}`}>
                        {other.other_name}:{other.other_number}人
                      </div>
                    )
                  } else {
                    return null // 名前がない場合は何も表示しない
                  }
                })
              return displayOthers
            } else {
              return (
                <div key={`operator-${index}`}>
                  {
                    operators?.find((ope: IOption) => {
                      return ope.id == operator.operator_id
                    })?.name
                  }
                  ：{operator.operator_number}人
                </div>
              )
            }
          })}
        </Col>
      </Row>
      <Row className="align-items-center py-3 border-bottom">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          建築会社
          <br />
          （統括安全衛生責任者）
        </Col>
        <Col md={12} lg={10}>
          {report?.construction_company}
        </Col>
      </Row>
      <Row className="align-items-center py-3 border-bottom bg-white">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          当日入場協力会社
        </Col>
        <Col md={12} lg={10}>
          <Row>
            {report?.partners?.map((partner: any, index: number) => (
              <Col md={6} lg={4} key={`partner-${index}`} className="mb-4">
                <div className="p-3 border rounded bg-light">
                  <p>会社名:{partner.name}</p>
                  <p>職長: {partner.leader_name}</p>
                  <p>人数: {partner.user_count}</p>
                  <p>評価: {partner.evaluation}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Row className="align-items-center py-3 border-bottom">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          点検分類
        </Col>
        <Col md={12} lg={10}>
          {report?.inspections?.map((inspection: string, index: number) => {
            return (
              <div key={`inspection-${index}`}>
                {
                  inspections?.find((ins: IOption) => {
                    return ins.id === inspection
                  })?.name
                }
              </div>
            )
          })}
        </Col>
      </Row>
      <Row className="align-items-center py-3 border-bottom bg-white">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          チェックリスト
        </Col>
        <Col lg={10}>
          {report.questions && report.questions.length > 0 && (
            <>
              <Row className="bg-dark text-white text-center fw-bold sticky-top py-2 d-xl-none">
                <Col xs={1}></Col>
                <Col xs={4}>点検分類</Col>
                <Col xs={6}>
                  <span className="d-inline-block bg-white text-success rounded px-2">大項目</span> - 項目名
                </Col>
                <Col xs={1}></Col>
              </Row>

              <Row className="bg-dark text-white text-center fw-bold sticky-top py-2 d-none d-xl-flex">
                <Col xl="auto" className="text-center" style={{ width: "4em" }}>
                  No
                </Col>
                <Col xl={2}>点検分類</Col>
                <Col xl={3}>大項目</Col>
                <Col xl={4}>項目名</Col>
                <Col xl={2}>チェック</Col>
              </Row>
            </>
          )}
          {report.questions && report.questions.length === 0 && <>-</>}
          {report?.questions?.map((question: IReportQuestion, index: number) => {
            return (
              <Fragment key={`question-${index}`}>
                <Row
                  id={question.inspection_id}
                  className={`align-items-center py-2 px-auto ${index % 2 === 0 ? "" : "bg-secondary bg-opacity-10"}`}
                  style={{ minHeight: "2.5em" }}
                >
                  <Col xs={1} xl={1} className="text-center">
                    {index + 1}.
                  </Col>
                  <Col xs={3} xl={2}>
                    {question.inspection_name || question.department_name}
                  </Col>
                  <Col xs={6} className="d-xl-none">
                    <span className="d-block text-success fw-bold">{question.class_name}</span>
                    {question.name}
                  </Col>
                  <Col xl={3} className="d-none d-xl-flex">
                    {question.class_name}
                  </Col>
                  <Col xl={4} className="d-none d-xl-flex">
                    {question.name}
                  </Col>
                  <Col xs={2} xl={2} className="text-center">
                    <QuestionAnswer resultId={String(question.result_id)} disabled />
                  </Col>
                  {(question.comment || question.image) && (
                    <>
                      <div className="mx-0 px-xl-5 mt-2">
                        <div
                          className={`alert ${
                            question?.result_id === "RESULT_CIRCLE"
                              ? "alert-success"
                              : question?.result_id === "RESULT_TRIANGLE"
                              ? "alert-warning"
                              : "alert-danger"
                          }`}
                        >
                          <Row>
                            <Col xl={3} className="fw-bold aling-middle">
                              指導または是正内容
                            </Col>
                            <Col xl={9} className="">
                              {question.comment?.split("\n")?.map((t: string, index: number) => {
                                return <p key={index}>{t}</p>
                              }) || "-"}
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col xl={3} className="fw-bold aling-middle">
                              アップロード画像
                            </Col>
                            <Col xl={9} className="">
                              {question.image ? (
                                <div className="bg-white border p-2 d-inline-block">
                                  <Image
                                    className="mw-100"
                                    src={`${process.env.MEDIA_DOMAIN}${question.image}`}
                                    rounded
                                  />
                                </div>
                              ) : (
                                <span>-</span>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </>
                  )}
                </Row>
              </Fragment>
            )
          })}
        </Col>
      </Row>
      <Row className="align-items-center py-3 border-bottom">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          総評
        </Col>
        <Col md={12} lg={10}>
          <ul>
            {comment.map((t: IReportComments, index: number) => {
              return (
                <li key={`comment-${index}`}>
                  記入者: {t.author}
                  <br />
                  内容: {t.comment}
                </li>
              )
            })}
          </ul>
        </Col>
      </Row>
      <Row className="align-items-center py-3 border-bottom">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          現場からの要望事項
        </Col>
        <Col md={12} lg={10}>
          <ul>
            {request_comment.map((t: IReportComments, index: number) => {
              return (
                <li key={`request_comment-${index}`}>
                  記入者: {t.author}
                  <br />
                  内容: {t.comment}
                </li>
              )
            })}
          </ul>
        </Col>
      </Row>
      <Row className="align-items-center py-3 border-bottom bg-white">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          メモ
        </Col>
        <Col md={12} lg={10}>
          {report?.note}
        </Col>
      </Row>
      <Container fluid className="mt-5 text-center">
        {report?.user_id === auth?.currentUser?.attributes?.sub && (
          <Row>
            <Col xs={4}>
              <Link to={`/`} className="btn btn-secondary w-100">
                一覧へ戻る
              </Link>
            </Col>
            <Col xs={4}>
              <Link to={`/report/${report?.id}/edit`} className="btn btn-warning w-100">
                更新
              </Link>
            </Col>
            <Col xs={4}>
              <Button onClick={() => handleDelete()} className="btn btn-danger w-100">
                削除
              </Button>
            </Col>
          </Row>
        )}
        {report?.user_id !== auth?.currentUser?.attributes?.sub && (
          <Row>
            <Col xs={6}>
              <Link to={`/`} className="btn btn-secondary w-75">
                一覧へ戻る
              </Link>
            </Col>
          </Row>
        )}
      </Container>
    </>
  )
}

export default App
